import { Controller } from 'stimulus';
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
//export { initFlatpickr };

export default class extends Controller {
	static targets = ['errorsContainer', 'btnOwForm', 'btnRtForm', 'arrivalDate', 'roundTrip', 'oneWay', 'multiplePaths', 'goDate', 'goDateOne', 'dateTwo', 'dateThree', 'dateFour', 'fieldsetOne', 'fieldsetTwo', 'fieldsetThree', 'removeBtn', 'formOw', 'formRt', 'formMult'];

  static values = {
    itinerary: Number,
  }

  connect() {
    // Loading Spinner doesn't appear in the beginning
    document.querySelector('.flights-spinner').hidden = true;
    // Verificar qual foi o último Radio Button selecionado antes da página ser recarregada
    window.addEventListener("onbeforeunload", this.rescueLastRadioButtonSelected());
  }

  // Data-action para os Radio Buttons
  wayRadioInput(e) {
    let radioValue;
    this.errorsContainerTarget.innerHTML = ''

    if (e.currentTarget.textContent === "Apenas Ida") {
      radioValue = 'ow';
      this.changeFormForEachRadioButton(1);
      this.cleanSessionStorage();
    } else if (e.currentTarget.textContent === "Ida e Volta") {
      radioValue = 'rt';
      this.changeFormForEachRadioButton(2);
      this.cleanSessionStorage();
    } else {
      radioValue = 'mult';
      this.changeFormForEachRadioButton(3);
      this.cleanSessionStorage();
    }
    this.saveRadioButtonSelected(radioValue);
  }

  // Limpar Storage
  cleanSessionStorage() {
    sessionStorage.removeItem('search_type');
  }

  // Salvar Radio Button selecionado
  saveRadioButtonSelected(value) {
    sessionStorage.setItem('search_type', value);
  }

  // Resgatar último Radio Button selecionado junto com o seu form (depois da página ser recarregada)
  rescueLastRadioButtonSelected(){
    // Retornar a value do Radio Button salvo ('ow', 'rt' ou 'multi')
    const saveButton = sessionStorage.getItem('search_type');

    // Array com os 3 Radio Buttons
    var radio = Array.from(document.getElementsByName('search_type'));

    radio.forEach((button) => {
      if (button.value.toString() === saveButton) {
        button.checked = true;
      }
    })

    // Form
    if (saveButton) {
      if (saveButton === 'ow') {
        this.changeFormForEachRadioButton(1);
      }
      else if (saveButton === 'rt') {
        this.changeFormForEachRadioButton(2);
      }
      else if (saveButton === 'mult') {
        this.changeFormForEachRadioButton(3);
      }
      else {
        // Quando saveButton == null, o form default é o de Ida e Volta ('rt')
        radio[1].checked = true;
        // this.changeFormForEachRadioButton(2);
      }
    } else {
      radio[1].checked = true;
    }
  }

  // Mudar o form de acordo com o Radio Button selecionado
  changeFormForEachRadioButton(type){
    const oneWay = this.oneWayTarget;
    const roundTrip = this.roundTripTarget;
    const multpaths = this.multiplePathsTarget;

    if (type == 1) {
      roundTrip.classList.add("d-none");
      oneWay.classList.remove("d-none");
      multpaths.classList.add("d-none");
    } else if (type == 2) {
      roundTrip.classList.remove("d-none");
      oneWay.classList.add("d-none");
      multpaths.classList.add("d-none");
    } else if (type == 3) {
      roundTrip.classList.add("d-none");
      oneWay.classList.add("d-none");
      multpaths.classList.remove("d-none");
    }
  }

  // Adicionar linha de campos no form de Múltiplos Trechos
  addFieldsLine(e) {
    e.preventDefault();
    let lastId = 0;

    if (this.fieldsetOneTarget.classList.contains('d-none')) {
      this.fieldsetOneTarget.classList.remove('d-none');
      this.fieldsetOneTarget.classList.add('d-flex');
      lastId = 1;
    } else if (this.fieldsetTwoTarget.classList.contains('d-none')) {
      this.fieldsetTwoTarget.classList.remove('d-none');
      this.fieldsetTwoTarget.classList.add('d-flex');
      lastId = 2;
    } else if (this.fieldsetThreeTarget.classList.contains('d-none')) {
      this.fieldsetThreeTarget.classList.remove('d-none');
      this.fieldsetThreeTarget.classList.add('d-flex');
      lastId = 3;
    }

    // Inicializa uma nova instância do Flatpickr para o novo campo adicionado
    // this.initFlatpickrForNewField(lastDate);

    // Botão de remover aparece quando tem mais de um campo
    if (lastId !== 0) {
      this.removeBtnTarget.classList.remove('d-none');
    }
  }

  // Remove linha de campos no form de Múltiplos Trechos
  removeFieldsLine(e) {
    e.preventDefault();
    let lastId = 3;

    if (this.fieldsetThreeTarget.classList.contains('d-flex')) {
      this.fieldsetThreeTarget.classList.remove('d-flex');
      this.fieldsetThreeTarget.classList.add('d-none');
      lastId = 2;
    } else if (this.fieldsetTwoTarget.classList.contains('d-flex')) {
      this.fieldsetTwoTarget.classList.remove('d-flex');
      this.fieldsetTwoTarget.classList.add('d-none');
      lastId = 1;
    } else if (this.fieldsetOneTarget.classList.contains('d-flex')) {
      this.fieldsetOneTarget.classList.remove('d-flex');
      this.fieldsetOneTarget.classList.add('d-none');
      lastId = 0;
    }

    if (lastId === 0) {
      this.removeBtnTarget.classList.add('d-none');
    }
  }

  // Inicializa uma nova instância do Flatpickr
  initFlatpickrForNewField(lastDate) {
    flatpickr('.datepickerFlightDate', {
      altInput: false,
      altFormat: "j, F Y",
      allowInput: false,
      dateFormat: "d/m/Y",
      locale: Portuguese,
      minDate: lastDate,
      maxDate: new Date().fp_incr(366), // a year from now
    });
  }

  /*******   Validação entre os campos com as datas de Ida e Volta   *******/
  disabledDate(){
    let ida = this.goDateOneTarget.value;

    // No campo "Data de Volta", o atributo 'disabled' é removido após d/m/Y ser informado no campo "Data de Ida"
    const backDate = this.arrivalDateTarget.children[0].children[1]
    backDate.removeAttribute('disabled');
    this.initFlatpickr(ida);
  }

  disabledDateMultOne(){
    let ida = this.goDateOneTarget.value;

    // No campo "Data de Volta", o atributo 'disabled' é removido após d/m/Y ser informado no campo "Data de Ida"
    const nextDate = this.dateTwoTarget;
    nextDate.removeAttribute('disabled');
    this.initFlatpickrMultOne(ida);
  }

  disabledDateMultTwo(){
    let ida = this.dateTwoTarget.value;

    // No campo "Data de Volta", o atributo 'disabled' é removido após d/m/Y ser informado no campo "Data de Ida"
    const nextDate = this.dateThreeTarget;
    nextDate.removeAttribute('disabled');
    this.initFlatpickrMultTwo(ida);
  }


  disabledDateMultThree(){
    let ida = this.dateThreeTarget.value;

    // No campo "Data de Volta", o atributo 'disabled' é removido após d/m/Y ser informado no campo "Data de Ida"
    const nextDate = this.dateFourTarget;
    nextDate.removeAttribute('disabled');
    this.initFlatpickrMultThree(ida);
  }


  // O que foi informado no campo "Data de Ida" torna-se a data mínima para o campo "Data de Volta"
  initFlatpickr = (ida) => {
    flatpickr(".datepickerFlightBackDate", {
      altInput: false,
      altFormat: "j, F Y",
      allowInput: false,
      dateFormat: "d/m/Y",
      locale: Portuguese,
      minDate: ida,
      maxDate: new Date().fp_incr(366) // a year from now
    });
  }

  initFlatpickrMultOne = (ida) => {
    console.log('primeiro', ida);

    flatpickr(".datepickerFlightDateTwo", {
      altInput: false,
      altFormat: "j, F Y",
      allowInput: false,
      dateFormat: "d/m/Y",
      locale: Portuguese,
      minDate: ida,
      maxDate: new Date().fp_incr(366) // a year from now
    });
  }

  initFlatpickrMultTwo = (ida) => {
    console.log('segundo', ida);

    flatpickr(".datepickerFlightDateThree", {
      altInput: false,
      altFormat: "j, F Y",
      allowInput: false,
      dateFormat: "d/m/Y",
      locale: Portuguese,
      minDate: ida,
      maxDate: new Date().fp_incr(366) // a year from now
    });
  }

  initFlatpickrMultThree = (ida) => {
    console.log('terceiro', ida);

    flatpickr(".datepickerFlightDateFour", {
      altInput: false,
      altFormat: "j, F Y",
      allowInput: false,
      dateFormat: "d/m/Y",
      locale: Portuguese,
      minDate: ida,
      maxDate: new Date().fp_incr(366) // a year from now
    });
  }

  // ------ SUBMITING ONE WAY FORM
  async submitOwForm(e){
    try {
      // Create JSON data to send to fetch;
      const formInfo = new FormData(e.currentTarget);

      const formDataObject = {};
      formInfo.forEach((value, key) => {
        formDataObject[key] = value;
      });

      // Create the final JSON object with nested "disponibility" key
      const finalData = {
        ...formDataObject,
        one_way_flight: {
          search_type: formDataObject.search_type,
          adults: formDataObject['one_way_flight[adults]'],
          children: formDataObject['one_way_flight[children]'],
          infants: formDataObject['one_way_flight[infants]'],
          origin: formDataObject['one_way_flight[origin]'],
          destiny: formDataObject['one_way_flight[destiny]'],
          date: formDataObject['one_way_flight[date]'],
          luggage: formDataObject['one_way_flight[luggage]'],
          cabin: formDataObject['one_way_flight[cabin]'],
        },
      };

      // Let Loading Spinner appearing while Flights are searched
      document.querySelector('.flights-spinner').hidden = false;

      // Send form data to async submission
      const token = document.getElementsByName("csrf-token")[0].content;
      await this.asyncOwFormSubmission(finalData, token);

    } catch (error) {
      console.error("Form submission error:", error);
    }
  }

  async asyncOwFormSubmission(params, token) {
    //console.log(params);
    event.preventDefault();
    const url = `${window.location.origin}/itineraries/${this.itineraryValue}/post-flights`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
      },
      body: JSON.stringify(params),
    });

    const responseData = await response.json();

    if (responseData.success) {
      window.location.href = `${window.location.origin}/itineraries/${this.itineraryValue}/show-disp`;
      // Hide Loading Spinner after Displaying Flights
      document.querySelector('.flights-spinner').hidden = true;
    } else {
      // Let Loading Spinner appears just for 0.5s and then Display errors
      setTimeout(function() { document.querySelector('.flights-spinner').hidden = true }, 500);
      this.displayOwErrors(responseData.errors, responseData.keys, params.one_way_flight)
    }
  }

  displayOwErrors(errors, errorsKeys, formParams) {
    const formKeys = Object.keys(formParams);
    formKeys.forEach((key) => {
      if (errorsKeys.includes(key)) {
        const input = document.getElementsByName(`one_way_flight[${key}]`)[0];
        input.classList.add('border-red');
      }
    });
    this.btnOwFormTarget.removeAttribute('disabled');
    this.errorsContainerTarget.innerHTML = "";

    const errorsTag = `<p> Resolva os problemas a seguir: </p>
                       <ul>
                       ${errors.map(error => `<li>${error}</li>`).join("")}
                       </ul>`
    this.errorsContainerTarget.insertAdjacentHTML('beforeend', errorsTag);
    this.formOwTarget.reset();
  }


  // ------ SUBMITING ROUND TRIP FORM
  async submitRtForm(e){
    try {
      // Create JSON data to send to fetch;
      const formInfo = new FormData(e.currentTarget);

      const formDataObject = {};
      formInfo.forEach((value, key) => {
        formDataObject[key] = value;
      });

      // Create the final JSON object with nested "disponibility" key
      const finalData = {
        ...formDataObject,
        round_trip_flight: {
          search_type: formDataObject.search_type,
          adults: formDataObject['round_trip_flight[adults]'],
          children: formDataObject['round_trip_flight[children]'],
          infants: formDataObject['round_trip_flight[infants]'],
          origin: formDataObject['round_trip_flight[origin]'],
          destiny: formDataObject['round_trip_flight[destiny]'],
          date_origin: formDataObject['round_trip_flight[date_origin]'],
          date_destiny: formDataObject['round_trip_flight[date_destiny]'],
          luggage: formDataObject['round_trip_flight[luggage]'],
          cabin: formDataObject['round_trip_flight[cabin]'],
        },
      };

      // Let Loading Spinner appearing while Flights are searched
      document.querySelector('.flights-spinner').hidden = false;

      e.currentTarget.reset();
      // Send form data to async submission
      const token = document.getElementsByName("csrf-token")[0].content;
      await this.asyncRtFormSubmission(finalData, token);

    } catch (error) {
      console.error("Form submission error:", error);
    }
  }

  async asyncRtFormSubmission(params, token) {
    //console.log(params);
    event.preventDefault();
    const url = `${window.location.origin}/itineraries/${this.itineraryValue}/post-flights`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
      },
      body: JSON.stringify(params),
    });

    const responseData = await response.json();

    if (responseData.success) {
      window.location.href = `${window.location.origin}/itineraries/${this.itineraryValue}/show-disp`;
      // Hide Loading Spinner after Displaying Flights
      document.querySelector('.flights-spinner').hidden = true;

    } else {
      // Let Loading Spinner appears just for 0.5s and then Display errors
      setTimeout(function() { document.querySelector('.flights-spinner').hidden = true }, 500);
      this.displayRtErrors(responseData.errors, responseData.keys, params.round_trip_flight)
    }
  }

  displayRtErrors(errors, errorsKeys, formParams) {
    const formKeys = Object.keys(formParams);
    formKeys.forEach((key) => {
      if (errorsKeys.includes(key)) {
        const input = document.getElementsByName(`round_trip_flight[${key}]`)[0];
        input.classList.add('border-red');
      }
    });
    this.btnRtFormTarget.removeAttribute('disabled');
    this.errorsContainerTarget.innerHTML = "";

    const errorsTag = `<p> Resolva os problemas a seguir: </p>
                       <ul>
                       ${errors.map(error => `<li>${error}</li>`).join("")}
                       </ul>`
    this.errorsContainerTarget.insertAdjacentHTML('beforeend', errorsTag);
    this.formRtTarget.reset();
  }

  // ------ SUBMITING MULT FORM
  async submitMultForm(e){
    try {
      // Create JSON data to send to fetch;
      const formInfo = new FormData(e.currentTarget);

      const formDataObject = {};
      formInfo.forEach((value, key) => {
        formDataObject[key] = value;
      });

      // Create the final JSON object with nested "disponibility" key
      const finalData = {
        ...formDataObject,
        multi_flight: {
          search_type: formDataObject.search_type,
          adults: formDataObject['multi_flight[adults]'],
          children: formDataObject['multi_flight[children]'],
          infants: formDataObject['multi_flight[infants]'],
          origin: formDataObject['multi_flight[origin]'],
          origin_two: formDataObject['multi_flight[origin_two]'],
          origin_three: formDataObject['multi_flight[origin_three]'],
          origin_four: formDataObject['multi_flight[origin_four]'],
          destiny: formDataObject['multi_flight[destiny]'],
          destiny_two: formDataObject['multi_flight[destiny_two]'],
          destiny_three: formDataObject['multi_flight[destiny_three]'],
          destiny_four: formDataObject['multi_flight[destiny_four]'],
          departure_date: formDataObject['multi_flight[departure_date]'],
          departure_date_two: formDataObject['multi_flight[departure_date_two]'],
          departure_date_three: formDataObject['multi_flight[departure_date_three]'],
          departure_date_four: formDataObject['multi_flight[departure_date_four]'],
          luggage: formDataObject['multi_flight[luggage]'],
          cabin: formDataObject['multi_flight[cabin]'],
        },
      };
      console.log(formDataObject);
      // Let Loading Spinner appearing while Flights are searched
      document.querySelector('.flights-spinner').hidden = false;

      e.currentTarget.reset();
      // Send form data to async submission
      const token = document.getElementsByName("csrf-token")[0].content;
      await this.asyncMultFormSubmission(finalData, token);

    } catch (error) {
      console.error("Form submission error:", error);
    }
  }

  async asyncMultFormSubmission(params, token) {
    console.log(params);
    event.preventDefault();
    const url = `${window.location.origin}/itineraries/${this.itineraryValue}/post-flights`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
      },
      body: JSON.stringify(params),
    });

    const responseData = await response.json();

    if (responseData.success) {
      window.location.href = `${window.location.origin}/itineraries/${this.itineraryValue}/show-disp`;
      // Hide Loading Spinner after Displaying Flights
      document.querySelector('.flights-spinner').hidden = true;

    } else {
      // Let Loading Spinner appears just for 0.5s and then Display errors
      setTimeout(function() { document.querySelector('.flights-spinner').hidden = true }, 500);
      // this.displayRtErrors(responseData.errors, responseData.keys, params.round_trip_flight)
    }
  }

}
