import { Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["messageInput", "chatBox", 'formChat', 'userMessage', 'loading'];

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "ChatChannel" },
      {
        connected: () => {
          console.log("Connected to ChatChannel");
        },
        received: (data) => {
          console.log(data);

          this.#appendMessage(data.question);
        },
      }
    );
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  sendMessage(event) {
    event.preventDefault();

    const message = this.messageInputTarget.value;
    if (message.trim() === "") return;

    this.subscription.send({ message: message });
    this.#appendMessage(message);
    this.messageInputTarget.value = "";
  }

  resetForm(e) {
    e.preventDefault();
    const formData = new FormData(this.formChatTarget)
    const jsonData = { question: {} }
    formData.forEach((value, key) => {
      jsonData.question[key] = value
    })

    fetch(this.formChatTarget.action, {
      method: "POST",
      body: JSON.stringify(jsonData),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          console.error("Error:", data.error)
        } else {
          console.log("Question created:", data)
          const filteredMessage = data.message.replace('Mande o roteiro completo com as últimas atualizações.', '');

          const userQuestion = `<p class='user-message'>${filteredMessage}</p>
                                <div data-chat-target='loading' class="chat-loading">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>`;
          this.chatBoxTarget.insertAdjacentHTML('beforeend', userQuestion);
          this.formChatTarget.reset();
        }
      })
      .catch(error => console.error("Submission error:", error))
  }

  #appendMessage(content) {
    this.chatBoxTarget.insertAdjacentHTML('beforeend', content);
    const newMessage = this.chatBoxTarget.lastElementChild;
    this.loadingTarget.remove();

    if (newMessage) {
      this.chatBoxTarget.scrollTo(0, newMessage.scrollHeight);
    }
  }
}
