import { Controller } from "stimulus"

export default class extends Controller {

  static values = { id: Number }

  connect() {}

  // Show or Hide Token on Index View
  async showHideToken(e) {
    const icon = e.currentTarget;
    // Token specific column in table
    const tokenContentId = document.getElementById(`ftoken${this.idValue}`);

    if (icon.classList.contains('fa-eye')) {
      // Show token
      await this.getToken();
    }
    else {
      // Hide token
      tokenContentId.innerHTML =
      `<span class="ftoken-dot">●●●●●●●●●●●●●●●●●●●</span> <i id="ftoken-icon${this.idValue}" class="far fa-eye" data-action="click->flight-setting#showHideToken"></i>`;
    }
  }

  // Calling Token through the method created in the controller (Rails)
  async getToken() {
    try {
      const url = `${window.location.origin}/flight_settings/${this.idValue}/find_token`;
      // Token specific column in table
      const tokenContentId = document.getElementById(`ftoken${this.idValue}`);

      fetch(url)
      .then(response => response.json())
      .then((data) => {
        const tokenValue = data.token;

        tokenContentId.innerHTML =
        `<span>${tokenValue}</span> <i id="ftoken-icon${this.idValue}" class="far fa-eye-slash" data-action="click->flight-setting#showHideToken"></i>`;
      })
    } catch (error) {
      console.log("Erro na requisição", error);
      return null
    }
  }

  // Copy Token on Show View
  copyToken(){
    const text = document.getElementById("currentToken");

    if (text) {
      navigator.clipboard.writeText(text.textContent);
      alert("Token copiado");
    }
  }
}
